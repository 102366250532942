/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Row,
  Col,
  Button,
  Card,
  Dropdown,
  Spinner,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { ContentState, convertToRaw, EditorState, convertFromHTML } from "draft-js";
/* ICON IMPORTS */
import PlusIcon from "@iconscout/react-unicons/icons/uil-plus";
import ActionIcon from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import UilTrash from "@iconscout/react-unicons/icons/uil-trash";
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import PreviousIcon from "@iconscout/react-unicons/icons/uil-previous";
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";

/* IMAGE IMPORT */
import AmberwoodPlaceholder from "../../../assets/logo.png";

/* API IMPORT */
import actions from "../../../redux/actions/userAction";
import {
  addBlog,
  deleteBlog,
  editBlog,
  getBlog,
  getBlogCategories,
} from "../../../service/api";

/* IMAGE URL */
import { IMAGE_URL } from "../../../config";

/* STYLES */
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  description: yup.string().required("Description is required."),
  addedBy: yup.string().required("Added by is required"),
  startDate: yup.string(),
  endDate: yup.string(),
  category: yup.object().required("Category is required"),
});

/* CATEGORY OPTIONS */

const Blogs = () => {
  const [addBlogModal, setAddBlogModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [previewUrl1, setPreviewUrl1] = useState(null);
  const [totalBlog, setTotalBlog] = useState(0);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editBlogModal, setEditBlogModal] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [blogDesc, setBlogDesc] = useState();

  const [editDetails, setEditDetails] = useState(null);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const pageSize = 12;
  const [totalPages, setTotalPages] = useState(0);

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    setLoading(true);
    setData([]);
    setPage(selectedPage);
  };
  const [categoryOptions, setCategoryOptions] = useState([]);

  const functionGetBlogCategory = () => {
    getBlogCategories()
      .then((res) => {
        if (res.status) {
          let tempArray = [];
          if (res?.data?.length > 0) {
            res?.data?.map((element, id) => {
              return tempArray.push({ value: element?._id, label: element?.name });
            });
          }
          setCategoryOptions(tempArray);
          // dispatch(actions.setBlog(res.data.docs));
        }
      })
      .finally((res) => {
        setBlogLoading(false);
      });
  };

  /* EDIT HANDLER FUNCTION */
  const handleEdit = (data) => {
    setEditDetails(data)
    setEditBlogModal(true);
  };

  /* DELETE HANDLER FUNCTION */
  const handleDelete = (id) => {
    setLoading(true);
    deleteBlog(id)
      .then((res) => {
        if (res?.status) {
          getBlogData();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      image: "",
      addedBy: "",
      isShow: "",
      startDate: new Date(),
      endDate: new Date(),
      category: "",
      addedByImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("name", values?.name);
      formData.append("description", values?.description);
      if (file) formData.append("image", file);
      formData.append("addedBy", values?.addedBy);
      formData.append("isShow", true);
      formData.append("startDate", values?.startDate);
      formData.append("endDate", values?.endDate);
      if (file1) formData.append("addedByImage", file1);
      formData.append("category", values?.category?.value);

      setLoading(true);
      addBlog(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            setAddBlogModal(false);
            getBlogData();
            setFile(null);
            setFile1(null);
            setPreviewUrl(null);
            setPreviewUrl1(null);
            formik.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });

    },
  });

  /* GET BLOGS DATA */
  const getBlogData = () => {
    setBlogLoading(true);
    const obj = {
      page: page,
      sizePerPage: pageSize,
    };
    getBlog(obj)
      .then((res) => {
        if (res.status) {
          setTotalBlog(res.data.totalDocs);
          setTotalPages(res?.data?.totalPages);
          setData(res.data?.docs);
          // dispatch(actions.setBlog(res.data.docs));
        }
      })
      .finally((res) => {
        setBlogLoading(false);
      });
  };

  /* IMAGE CHANGE HANDLER */
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    formik.handleChange(e);
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
    } else {
      setPreviewUrl(null);
    }

    e.target.value = "";
  };

  /* DELETE HANDLER FUNCTION */
  const handleDeleteClick = () => {
    setFile(null);
    setPreviewUrl(null);
  };

  /* IMAGE CHANGE HANDLER */
  const handleImageChange1 = (e) => {
    const selectedFile = e.target.files[0];
    setFile1(selectedFile);
    formik.handleChange(e);
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setPreviewUrl1(reader.result);
      };
    } else {
      setPreviewUrl1(null);
    }

    e.target.value = "";
  };

  /* DELETE CHANGE HANDLER */
  const handleDeleteClick1 = () => {
    setFile1(null);
    setPreviewUrl1(null);
  };

  /* USE EFFECT */
  useEffect(() => {
    functionGetBlogCategory();
    getBlogData();
  }, []);

  useEffect(() => {
    getBlogData();
  }, [page]);

  const EditBlogComponent = () => {

    const [descriptionEditor, setDescriptionEditor] = useState(null);
    const [editImage, setEditImages] = useState([])
    const [editPreviewImage, setEditPreviewImages] = useState(null)

    const formik = useFormik({
      initialValues: {
        name: "",
        description: "",
        image: "",
        addedBy: "",
        isShow: "",
        startDate: new Date(),
        endDate: new Date(),
        category: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log(values)
        console.log(editImage)
        let formData = new FormData();
        formData.append("name", values?.name);
        if (editImage?.length > 0) {
          formData.append("image", editImage);
        }
        formData.append("addedBy", values?.addedBy);
        formData.append("isShow", values?.isShow);
        formData.append("startDate", values?.startDate);
        formData.append("endDate", values?.endDate);
        formData.append("description", values?.description);
        formData.append("category", values?.category?.value);
        formData.append("blogImage", values?.image);
        setLoading(true);
        editBlog(editDetails?._id, formData).then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            setEditBlogModal(false);
            getBlogData();
            setEditImages(null);
            setEditPreviewImages(null);
            setDescriptionEditor(null);
            formik.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
          .catch((e) => {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            setLoading(false);
          });

      }
    })
    console.log("EditDetails", formik.values)
    useEffect(() => {
      if (editDetails) {
        formik.setFieldValue("name", editDetails?.name);
        formik.setFieldValue("image", editDetails?.image);
        formik.setFieldValue("addedBy", editDetails?.addedBy);
        formik.setFieldValue("isShow", editDetails?.isShow);
        formik.setFieldValue("startDate", new Date(editDetails?.startDate));
        formik.setFieldValue("endDate", new Date(editDetails?.endDate));
        const blocksFromHtml = htmlToDraft(editDetails?.description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setDescriptionEditor(editorState)
        formik.setFieldValue("description", editDetails?.description)
        formik.setFieldValue("category", categoryOptions?.find((e) => e?.value === editDetails?.category?._id));
      }
    }, [editDetails])


    /* IMAGE CHANGE HANDLER */
    const handleImageChange = (e) => {
      const selectedFile = e.target.files[0];
      setEditImages(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = () => {
          setEditPreviewImages(reader.result);
        };
      } else {
        setEditPreviewImages(null);
      }

      e.target.value = "";
    };

    /* DELETE HANDLER FUNCTION */
    const handleEditDeleteClick = () => {
      setEditImages(null);
      setEditPreviewImages(null);
    };

    return <>
      {/* EDIT BLOG MODAL */}
      <Modal
        centered
        size="lg"
        show={editBlogModal}
        onHide={() => setEditBlogModal(!editBlogModal)}
      >
        <form className="add-user-form" onSubmit={formik.handleSubmit}>
          <Modal.Header className="add-user-modal-header">
            <h5>{"Edit"} Blog</h5>
          </Modal.Header>
          <Modal.Body className="add-blog-modalbody">
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label">
                    Blog Cover Image <span>*</span>
                  </label>
                  <div className="profile-img-agent">
                    <div className="custom-file-input-css">
                      <div className="img-selection-box">
                        <div>
                          <ImportIcon size="20" color="#323232" />
                          <p>Upload from local computer</p>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="image-control"
                        name="image"
                        id="image"
                        placeholder="Reward Name"
                        onChange={handleImageChange}
                      />
                    </div>

                    {editPreviewImage ? (
                      editPreviewImage?.startsWith("data:image") ? (
                        <div className="uploaded-image">
                          <img src={editPreviewImage} alt="Profile" />
                          <div className="action-btns">
                            <Button
                              className="remove-btn"
                              onClick={handleEditDeleteClick}
                            >
                              <UilTrash size="15" color="#323232" />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="uploaded-image">
                          <img src={`${editPreviewImage}`} alt="Profile" />
                          <div className="action-btns">
                            <Button
                              className="remove-btn"
                              onClick={handleEditDeleteClick}
                            >
                              <UilTrash size="15" color="#323232" />
                            </Button>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="profile-img-agent">
                  <div className="uploaded-image">
                    <img src={IMAGE_URL + `${formik.values.image}`} alt="Profile" />
                  </div>
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Blog Title <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formik.errors.name && formik.touched.name
                      ? "validation-border"
                      : ""
                      }`}
                    name="name"
                    id="name"
                    placeholder="Web & Video Marketing"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Blog Description <span>*</span>
                  </label>
                  <Editor
                    editorClassName="texteditor-control"
                    toolbarClassName="toolbar-control"
                    placeholder="Description about the blog"
                    editorState={descriptionEditor}
                    onEditorStateChange={(e) => {
                      setDescriptionEditor(e);
                      formik.setFieldValue("description", draftToHtml(convertToRaw(e.getCurrentContent())))
                    }}
                  />
                  {formik.errors.description && formik.touched.description && (
                    <small style={{ color: "red" }}>
                      {formik.errors.description}
                    </small>
                  )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Added by
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="addedBy"
                    id="addedBy"
                    placeholder="Web & Video Marketing"
                    value={formik.values.addedBy}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.addedBy && formik.touched.addedBy && (
                    <small style={{ color: "red" }}>
                      {formik.errors.addedBy}
                    </small>
                  )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Category
                  </label>
                  <Select
                    options={categoryOptions}
                    className="select-control"
                    classNamePrefix="custom"
                    placeholder="Category"
                    value={formik.values.category}
                    onChange={(e) => formik.setFieldValue("category", e)}
                  />
                  {formik.errors.category && formik.touched.category && (
                    <small style={{ color: "red" }}>
                      {formik.errors.category}
                    </small>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setEditBlogModal(!editBlogModal)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" type="submit" disabled={loading}>
              {" "}
              {loading ? <Spinner /> : "Edit Blog"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  }

  return (
    <section className="blogs">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Blogs</h1>
              <p style={{ fontSize: "15px" }}>
                Add and manage blogs in the Amberwood Real Estate website.
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                <Button
                  className="import-btn"
                  onClick={() => setAddBlogModal(!addBlogModal)}
                >
                  {" "}
                  <PlusIcon size="16" color="#323232" />
                  Add Blog
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* BLOGS LIST */}
        <div className="blogs-list">
          <Card className="list-card">
            <Card.Header className="list-cardheader">
              <p>
                Showing <b>{data?.length}</b> blogs
              </p>
              <div className="right-header">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                /> */}
              </div>
            </Card.Header>
            <Card.Body className="list-cardbody">
              <Row className="gy-4">
                {blogLoading ? (
                  <>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={AmberwoodPlaceholder} alt="Blog Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              {/* <Dropdown.Item className="menu-item">
                                Edit Blog
                              </Dropdown.Item> */}
                              <Dropdown.Item className="menu-item">
                                Delete Blog
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={AmberwoodPlaceholder} alt="Blog Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              {/* <Dropdown.Item className="menu-item">
                                Edit Blog
                              </Dropdown.Item> */}
                              <Dropdown.Item className="menu-item">
                                Delete Blog
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={AmberwoodPlaceholder} alt="Blog Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              {/* <Dropdown.Item className="menu-item">
                                Edit Blog
                              </Dropdown.Item> */}
                              <Dropdown.Item className="menu-item">
                                Delete Blog
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={AmberwoodPlaceholder} alt="Blog Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              {/* <Dropdown.Item className="menu-item">
                                Edit Blog
                              </Dropdown.Item> */}
                              <Dropdown.Item className="menu-item">
                                Delete Blog
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  data.map((blog, _id) => {
                    return (
                      <>
                        <div className="col-xl-3 col-sm-12" key={_id}>
                          <div className="blog-card">
                            <div className="blog-img">
                              <img
                                src={IMAGE_URL + blog.image}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = AmberwoodPlaceholder;
                                }}
                                alt="Blog Cover"
                              />
                              <Dropdown
                                className="card-action"
                                placement="left"
                              >
                                <Dropdown.Toggle>
                                  <ActionIcon size="16" color="#323232" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="card-action-menu">
                                  <Dropdown.Item
                                    className="menu-item"
                                    onClick={() => handleEdit(blog)}
                                  >
                                    Edit Blog
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="menu-item"
                                    onClick={() => handleDelete(blog?._id)}
                                  >
                                    Delete Blog
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="blog-details">
                              <div className="filters">
                                <div className="filter-badge">
                                  <p>{blog.category?.name}</p>
                                </div>
                              </div>
                              <h5>{blog.name}</h5>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </Row>
              <ReactPaginate
                className="blogs-pagination"
                previousLabel={
                  <Button className="blog-pagination-btn">
                    <PreviousIcon color="#323232" size="20" />
                  </Button>
                }
                nextLabel={
                  <Button className="blog-pagination-btn">
                    <NextIcon color="#323232" size="20" />
                  </Button>
                }
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                activeClassName={"active"}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* ADD BLOG MODAL */}
      <Modal
        centered
        size="lg"
        show={addBlogModal}
        onHide={() => setAddBlogModal(!addBlogModal)}
      >
        <form className="add-user-form" onSubmit={formik.handleSubmit}>
          <Modal.Header className="add-user-modal-header">
            <h5>{"Add"} Blog</h5>
          </Modal.Header>
          <Modal.Body className="add-blog-modalbody">
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label">
                    Blog Cover Image <span>*</span>
                  </label>
                  <div className="profile-img-agent">
                    <div className="custom-file-input-css">
                      <div className="img-selection-box">
                        <div>
                          <ImportIcon size="20" color="#323232" />
                          <p>Upload from local computer</p>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="image-control"
                        name="image"
                        id="image"
                        placeholder="Reward Name"
                        onChange={handleImageChange}
                      />
                    </div>

                    {previewUrl ? (
                      previewUrl.startsWith("data:image") ? (
                        <div className="uploaded-image">
                          <img src={previewUrl} alt="Profile" />
                          <div className="action-btns">
                            <Button
                              className="remove-btn"
                              onClick={handleDeleteClick}
                            >
                              <UilTrash size="15" color="#323232" />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="uploaded-image">
                          <img src={`${previewUrl}`} alt="Profile" />
                          <div className="action-btns">
                            <Button
                              className="remove-btn"
                              onClick={handleDeleteClick}
                            >
                              <UilTrash size="15" color="#323232" />
                            </Button>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Blog Title <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formik.errors.name && formik.touched.name
                      ? "validation-border"
                      : ""
                      }`}
                    name="name"
                    id="name"
                    placeholder="Web & Video Marketing"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Blog Description <span>*</span>
                  </label>
                  <Editor
                    editorClassName="texteditor-control"
                    toolbarClassName="toolbar-control"
                    placeholder="Description about the blog"
                    editorState={blogDesc}
                    onEditorStateChange={(e) => {
                      setBlogDesc(e);
                      formik.setFieldValue(
                        "description",
                        draftToHtml(convertToRaw(e.getCurrentContent()))
                      );
                      // setInput({
                      //   ...input,
                      //   descriptionState: e,
                      //   description: draftToHtml(convertToRaw(e.getCurrentContent())),
                      // });
                    }}
                  />
                  {formik.errors.description && formik.touched.description && (
                    <small style={{ color: "red" }}>
                      {formik.errors.description}
                    </small>
                  )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Added by
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="addedBy"
                    id="addedBy"
                    placeholder="Web & Video Marketing"
                    value={formik.values.addedBy}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.addedBy && formik.touched.addedBy && (
                    <small style={{ color: "red" }}>
                      {formik.errors.addedBy}
                    </small>
                  )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Category
                  </label>
                  <Select
                    options={categoryOptions}
                    className="select-control"
                    classNamePrefix="custom"
                    placeholder="Category"
                    value={formik.values.category}
                    onChange={(e) => formik.setFieldValue("category", e)}
                  />
                  {formik.errors.category && formik.touched.category && (
                    <small style={{ color: "red" }}>
                      {formik.errors.category}
                    </small>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setAddBlogModal(!addBlogModal)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" type="submit" disabled={loading}>
              {" "}
              {loading ? <Spinner /> : "Add Blog"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <EditBlogComponent />

    </section>
  );
};

export default Blogs;
