import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const getContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/contact-us`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getContactUsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editContactUs = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/contact-us/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteContactUs = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs API
export const addBlog = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/blog`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlog = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/blog`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/blog/`, + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlog = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/blog/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlog = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs Category API
export const addBlogCategory = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/blogCategory`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogCategories = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/blogCategory`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlogCategory = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/blogCategory/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlogCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/blogCategory/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
